import React from "react"
import imgPortadaEbook from "@components/pageEbook/ebooks/ebook15/image/imgPortadaEbook.png"
import img1 from "@components/pageEbook/ebooks/ebook15/image/imgCard1.svg"
import img2 from "@components/pageEbook/ebooks/ebook15/image/imgCard2.svg"
import img3 from "@components/pageEbook/ebooks/ebook15/image/imgCard3.svg"
import slider1 from "@components/pageEbook/ebooks/ebook15/image/imgSilder1.png"
import slider2 from "@components/pageEbook/ebooks/ebook15/image/imgSilder2.png"
import slider3 from "@components/pageEbook/ebooks/ebook15/image/imgSilder3.png"

const title = (
  <>
  <h1 className="titles-underlined-ebook ebook-ventas">
      ¿Cómo hacer un análisis de atención al cliente para aumentar la
      satisfacción?
    </h1>
  </>
)

const textDescription = (
  <p className="text-h2-ebook" style={{ marginTop: "20px" }}>
    Según Gartner, el 81% de los profesionales de marketing consideran que la
    satisfacción del cliente es el principal campo de competencia en su
    industria. En términos sencillos, la satisfacción es una medida que
    determina qué tan bien los productos o servicios.
    <br />
    <br />
    PwC descubrió que el 59% de los clientes abandonarán una empresa después de
    varias malas experiencias, y el 17% después de una sola mala experiencia.
  </p>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
    <br />
    <span> ¡Descuida que te contactaremos solo por cosas importantes!</span>
  </span>
)

const textOne = (
  <p>
    Ahora, es importante que categorices a tus clientes en diferentes grupos
    para poder obtener conclusiones precisas sobre su comportamiento y
    necesidades. Al hacerlo, ten en cuenta algunos aspectos clave.
    <br /> <br />
    Primero, asegúrate de que las diferencias entre los grupos sean notables y
    distintivas. Esto significa que los segmentos de clientes deben tener
    diferencias observables para que puedas definirlos claramente.
  </p>
)
const textTwo = (
  <p>
    Una vez que conoces quiénes son tus clientes, es el momento de indagar en
    sus necesidades y cómo puedes servirles mejor. En esta etapa, la clave
    radica en descubrir cuáles son sus problemas más grandes.
    <br /> <br />
    Puedes hacer esto de varias maneras. Primero, puedes llevar a cabo una
    encuesta si cuentas con suficientes participantes.
  </p>
)
const textThree = (
  <p>
    Puedes hacer una lista de todas las formas en que tu producto beneficiará al
    cliente objetivo. Esta información se puede utilizar en tu historia de marca
    y en otros materiales de marketing.
  </p>
)

const data = {
  start: {
    support: "Ebook | Atención al cliente",
    title: title,
    description: textDescription,
    classtext: "",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Ebook",
    img: imgPortadaEbook,
  },

  slider: {
    title: true,
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Importancia de la Satisfacción del Cliente",
      },
      {
        key: 2,
        text: "Cómo hacer un análisis de atención al cliente",
      },
      {
        key: 3,
        text:
          "7 formas de recopilar datos para un análisis de atención al cliente",
      },
      {
        key: 4,
        text: "Tipos de reportes de servicio el cliente",
      },
      {
        key: 5,
        text:
          "Conocer las necesidades de tus clientes y cómo puedes servirles mejor",
      },
      {
        key: 6,
        text:
          "Reconocer las formas en que tu producto beneficiará a tu cliente",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        title: "Crea un sentido y ofertas de urgencia",
        text: textOne,
        photo: img1,
        class: true,
      },
      {
        title: "Utiliza técnicas de storytelling  ",
        text: textTwo,
        photo: img2,
        class: "",
      },
      {
        title: "Método Merrill-Reid  ",
        text: textThree,
        photo: img3,
        class: true,
      },
    ],
  },
}

export default data
